// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-education-js": () => import("./../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-philosophy-js": () => import("./../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-priorworks-js": () => import("./../src/pages/priorworks.js" /* webpackChunkName: "component---src-pages-priorworks-js" */)
}

